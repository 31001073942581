@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=fallback');

@tailwind base;
@tailwind components;

// Additional styles
@import 'additional-styles/utility-patterns.scss';
@import 'additional-styles/range-slider.scss';
@import 'additional-styles/toggle-switch.scss';
@import 'additional-styles/flatpickr.scss';
@import 'toastr';
@import 'additional-styles/theme.scss';

@tailwind utilities;

// See Alpine.js: https://github.com/alpinejs/alpine#x-cloak
[x-cloak=""] {
    display: none;
}

@media screen and (max-width: theme('screens.lg')) {
    [x-cloak="lg"] { display: none; }
}
